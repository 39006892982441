@import '/src/scss/variables';

.top_five_container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
}

.top_five {
  .title {
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }

  .link {
    color: $blue;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
  }
}

.line {
  margin: 32px 0;
  border-bottom: 2px solid black;
}

.share_header {
  margin: auto;
  text-align: center;
}

.share_container {
  display: flex;
  gap: 32px;
  justify-content: center;
  margin-top: 16px;
  font-size: 24px;

  .share {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}

.tooltip {
  margin: auto;
}
