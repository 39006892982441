@import '/src/scss/variables';

.slick-arrow,
.slick-prev,
.slick-next {
  border-radius: 50%;
  width: 60px !important;
  height: 60px !important;
  z-index: 1 !important;

  &::before {
    display: none !important;
  }
}

.slick-prev {
  background-image: url('/../public/arrow-left.svg') !important;
  background-size: contain !important;
  left: -30px !important;
}

.slick-next {
  background-image: url('/../public/arrow-right.svg') !important;
  background-size: contain !important;
  right: -30px !important;
}
