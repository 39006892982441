@import '/src/scss/variables';

.container {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background: $blue-secondary;
  color: $white;
  transition: max-height 0.5s ease-in-out;
  overflow: hidden;
}

.pulse {
  display: flex;
  flex-direction: column;
  width: 960px;
  max-width: calc(100vw - 32px);
  gap: 16px;
  padding: 16px 32px 48px;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  opacity: 0;
  animation: fadeIn 2s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
}

.column {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 8px;
}

.title {
  display: flex;
  gap: 8px;
  align-items: center;

  p {
    font-size: 32px;
    white-space: nowrap;

    .delta {
      font-size: 26px;
      font-family: 'Roboto', sans-serif;
    }
  }
}

.text {
  font-weight: 400;
}

.content {
  transition: 1s opacity;
}

.overall_sentiment {
  display: flex;
  justify-content: center;
  gap: 16px;

  p {
    font-size: 32px;
  }
}

.ratios {
  display: flex;
  align-items: center;
  gap: 16px;

  .ratio {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 26px;
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    color: $blue-secondary;
    background: $white;
    border-radius: 8px;
    width: 16px;
    height: 16px;
    font-size: 12px;
  }

  .selected {
    color: $yellow !important;
    border-bottom: 2px solid $yellow;

    .icon {
      color: $blue-secondary;
      background: $yellow;
    }
  }
}

.trend {
  font-size: 18px;

  .increase {
    color: $green;
  }

  .decrease {
    color: $red;
  }
}

.share {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $yellow;
  color: $blue-secondary;
  border-radius: 10px;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
}

.knob {
  cursor: pointer;
  z-index: 2;
  background-image: url('/../public/arrow-down.svg');
  background-size: contain;
  height: 42px;
  width: 42px;
  margin: -21px auto;
  transition: opacity 1s, 0s background-image 0.4s;

  &.loading {
    opacity: 0;
  }

  &.open {
    background-image: url('/../public/arrow-up.svg');
  }
}

@media (max-width: $screen-size-s) {
  .container {
    width: 100%;
    margin-top: 76px;
  }

  .pulse {
    flex-direction: column;
    margin-top: 0;
  }

  .title {
    h2 {
      font-size: 32px;

      .delta {
        font-size: 24px;
      }
    }
  }

  .top {
    gap: 4px;
  }

  .overall_sentiment {
    flex-direction: column;
    gap: 0;
    margin-bottom: 8px;
  }

  .text {
    font-size: 16px;
  }

  .ratio {
    font-size: 24px;
  }
}

@media (max-width: $screen-size-xs) {
  .title {
    h2 {
      font-size: 24px;

      .delta {
        font-size: 20px;
      }
    }
  }

  .ratio {
    font-size: 18px;
  }
}
