$screen-size-l: 1080px;
$screen-size-m: 900px;
$screen-size-s: 720px;
$screen-size-xs: 410px;

$white: #ffffff;
$black: #161616;
$blue: #015bd7;
$blue-secondary: #6893cd;
$yellow: #ffe21f;
$green: #5cd996;
$red: #ff0000;
