@import '/src/scss/variables';

.banner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-repeat: no-repeat;
  background-position: calc(100% + 200px) 50%;
}

.subtitle {
  max-width: calc(100% - 32px);
  font-size: 30px;
  text-align: center;
}

@media (max-width: $screen-size-s) {
  .banner {
    padding: 32px 0;
  }
}
