@import '/src/scss/variables';

.container {
  width: 960px;
  max-width: calc(100vw - 32px);
  background: $white;
  padding: 16px 24px;
  border-radius: 16px;
  color: $black;
}

.row {
  display: flex;
  justify-content: space-between;
  gap: 16px;
}

.title {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 22px;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background: $blue;
  border-radius: 8px;
  min-width: 16px;
  height: 16px;
  font-size: 12px;
}

.graph {
  height: 360px;
  padding: 16px 0;
}

.date_ranges {
  display: flex;
  align-items: center;
  gap: 16px;

  .date_range {
    display: flex;
    align-items: center;
    gap: 4px;
    font-size: 22px;
    cursor: pointer;
    white-space: nowrap;
  }

  .selected {
    color: $blue !important;
    border-bottom: 2px solid $blue;
  }
}

@media (max-width: $screen-size-s) {
  .container {
    padding: 16px 8px;
  }

  .title {
    font-size: 18px;
  }

  .date_ranges {
    .date_range {
      font-size: 18px;
    }
  }
}
