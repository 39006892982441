@import '/src/scss/variables';

.delta_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-bottom: 8px;
}

.ratio_container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
}

.ratio {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 26px;
  font-family: 'Roboto', sans-serif;
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  background: $blue;
  border-radius: 12px;
  width: 24px;
  height: 24px;
  font-size: 20px;
}

.delta {
  font-size: 26px;
  font-family: 'Roboto', sans-serif;
}

.trend {
  font-size: 18px;

  .increase {
    color: $green;
  }

  .decrease {
    color: $red;
  }
}

.info {
  text-align: center;
}

.line {
  margin: 32px 0;
  border-bottom: 2px solid black;
}

.share_header {
  margin: auto;
  text-align: center;
}

.share_container {
  display: flex;
  gap: 32px;
  justify-content: center;
  margin-top: 16px;
  font-size: 24px;

  .share {
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
}

.tooltip {
  margin: auto;
}
