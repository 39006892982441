@import '/src/scss/variables';

.card {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 32px 64px;
  gap: 16px;
  color: $white;
  opacity: 0;
  animation: fadeIn 2s forwards;

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  a {
    color: $white;
  }

  .img_container {
    min-width: 400px;
    min-height: 400px;
    border-radius: 16px;
    overflow: hidden;
  }

  .img {
    background-repeat: no-repeat;
    align-self: center;
    width: 400px;
    height: 400px;
    transition: transform 0.5s;

    &:hover {
      transform: scale(1.05);
    }
  }

  .info_container {
    display: flex;
    flex-direction: column;
    min-height: 360px;
    gap: 24px;
  }

  .header {
    display: flex;
    align-items: center;
    gap: 16px;
    width: fit-content;

    h2 {
      font-family: 'Roboto', sans-serif;
      font-size: 32px;
      padding-bottom: 4px;
      border-bottom: 3px solid $yellow;
    }
  }

  .header_mobile {
    display: none;
    align-items: center;
    gap: 16px;
    width: fit-content;
    margin-bottom: 8px;

    h2 {
      font-family: 'Roboto', sans-serif;
      font-size: 24px;
      padding-bottom: 4px;
      border-bottom: 3px solid $yellow;
    }
  }

  .share {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: $white;
    border-radius: 10px;
    width: 20px;
    min-width: 20px;
    height: 20px;
    font-size: 12px;
    cursor: pointer;
    color: $blue;
  }

  .info {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }

  .title {
    font-size: 32px;
  }

  .description {
    font-size: 24px;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 1.2em;
  }

  .source {
    font-family: 'Roboto', sans-serif;
    font-style: italic;
    font-size: 16px;
    letter-spacing: 1.6px;
    color: $yellow;
    max-width: 100%;
    overflow: hidden;
    display: -webkit-box;
    line-clamp: 1;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    line-height: 1.2em;
    min-height: 1.2em;

    .site_name {
      text-decoration: underline;
    }
  }

  .grow {
    flex-grow: 1;
  }
}

@media (max-width: $screen-size-l) {
  .card {
    .info_container {
      min-height: 320px;
    }

    .img_container {
      min-width: 360px;
      min-height: 360px;
    }

    .img {
      width: 360px;
      height: 360px;
    }

    .title {
      line-clamp: 4;
      -webkit-line-clamp: 4;
      min-height: 0;
    }
  }
}

@media (max-width: $screen-size-m) {
  .card {
    .info_container {
      min-height: 280px;
    }

    .img_container {
      min-width: 320px;
      min-height: 320px;
    }

    .img {
      width: 320px;
      height: 320px;
    }
  }
}

@media (max-width: $screen-size-s) {
  .card {
    flex-direction: column-reverse !important;
    padding: 32px;

    .header {
      display: none;
    }

    .header_mobile {
      display: flex;
    }

    .grow {
      display: none;
    }

    .info_container {
      min-height: fit-content;
    }

    .img_container {
      min-width: 0;
      width: 100%;
      min-height: 0;
      height: 240px;
    }

    .img {
      height: 240px;
      width: 100%;
    }

    .title {
      font-size: 24px;
    }

    .description {
      font-size: 18px;
    }
  }
}

@media (max-width: $screen-size-xs) {
  .card {
    .img_container {
      height: 180px;
    }

    .img {
      height: 180px;
    }
  }
}
