@import '/src/scss/variables';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
  padding: 32px;
  gap: 24px;
  flex-wrap: wrap;
  position: relative;
  overflow: hidden;
  background: $blue;
}

.brand {
  display: flex;
  flex-direction: column;
}

.powered_by {
  margin-left: 16px;
  font-family: 'Roboto', sans-serif;
  color: $white;
}

.logo {
  margin-bottom: -8px;
  height: 48px;
  cursor: pointer;
}

.info {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 24px;
}

.copyrights {
  display: flex;
  gap: 16px;
  font-family: 'Roboto', sans-serif;
  color: $white;
}

.socials {
  display: flex;
  gap: 16px;
  color: $white;
  font-size: 32px;

  a {
    color: $white;
    cursor: pointer;
  }
}

@media (max-width: $screen-size-s) {
  .footer {
    flex-direction: column;
    align-items: center;
  }

  .info {
    align-items: center;
  }
}
