@import url('https://fonts.googleapis.com/css2?family=Yrsa:ital,wght@0,300..700;1,300..700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500');
@import url('https://fonts.googleapis.com/css?family=Inter:300,400,500');
@import '~slick-carousel/slick/slick.css';
@import '~slick-carousel/slick/slick-theme.css';
@import '/src/scss/variables';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  font-size: 13px;
  margin: 0;
  font-family: 'Yrsa', serif;
  color: $black;
  overflow-x: clip;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

a {
  text-decoration: none;
}

::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 4px;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

// Firefox does not support changing the border-radius of the scrollbar thumb
html {
  scrollbar-width: none;
}
