@import '/src/scss/variables';

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 32px 24px;
  background: $blue;
  border-bottom: 2px solid $white;
  gap: 16px;
  z-index: 3;
}

.logo {
  height: 42px;
  line-height: 0;
  cursor: pointer;
}

.logo_blue {
  margin: auto;
  height: 32px;
  line-height: 0;
  cursor: pointer;
}

.menu {
  display: flex;
  gap: 32px;
  align-items: center;
}

.about {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 1.5px;
  font-weight: 600;
  padding-bottom: 2px;
  border: none;
  border-bottom: 2px solid $yellow;
  color: $yellow;
  background: transparent;
  cursor: pointer;
}

.date {
  color: $white;
  font-size: 18px;
}

.description {
  flex-grow: 1;
  font-size: 24px;
  text-align: center;
  line-height: 28px;
}

.subscribe {
  font-family: 'Roboto', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  font-weight: 600;
  background: $yellow;
  border: none;
  padding: 5px 18px;
  border-radius: 4px;
  cursor: pointer;
}

@media (max-width: $screen-size-m) {
  .menu {
    flex-direction: column-reverse;
    align-items: end;
    gap: 8px;
  }

  .date {
    display: none;
  }
}

@media (max-width: $screen-size-s) {
  .header {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1000;
    padding: 16px;
  }

  .logo {
    height: 42px;
  }
}

@media (max-width: $screen-size-xs) {
  .logo {
    height: unset;
    max-width: 50%;
  }
}
